// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import 'daterangepicker/daterangepicker.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'select2/dist/css/select2.css';
import 'jquery.add-input-area';
import '@zxing/browser';
import '@zxing/library';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

require("easy-autocomplete");
require("jquery");
require("jquery-ui");

require("trix")
require("@rails/actiontext")